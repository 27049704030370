export interface AddTransactionRequest {
   splits?: BaseTransactionCategorySplitDto[] | null
   budgetId: string
   transactionId: string
   bankAccountId?: string | null
   transactionDraftId?: string | null
   type: TransactionType
   /** @format date */
   date: Date
   /** @format double */
   value: number
   currency: string
   note: string
   key: string
}

export interface AddTransferRequest {
   budgetId: string
   transactionId: string
   bankAccountId?: string | null
   targetBankAccountId?: string | null
   /** @format date */
   date: Date
   /** @format double */
   value: number
   currency: string
   note: string
}

export interface AssignmentRecordDto {
   id: string
   budgetId: string
   periodKey: string
   fromCategoryId?: string | null
   toCategoryId?: string | null
   /** @format double */
   value: number
   /** @format date */
   date: Date
}

export interface AuthRequest {
   secret: string
   email: string
   reasons: string
}

export interface AutoAssignItemDto {
   categoryId: string
   /** @format double */
   value: number
}

export interface AutoAssignRequest {
   periodKey: string
   budgetId: string
   items?: AutoAssignItemDto[] | null
}

export interface BankAccountDto {
   id: string
   name: string
   status: BankAccountStatus
   /** @format int32 */
   order: number
}

export enum BankAccountStatus {
   Active = 'Active',
   Closed = 'Closed'
}

export interface BaseTransactionCategorySplitDto {
   id: string
   categoryId: string
   categoryName: string
   categoryType: string
   /** @format double */
   value: number
   currency: string
}

export interface BudgetCollaborationDto {
   id: string
   collaboratorEmail: string
}

export interface BudgetDto {
   id: string
   name: string
   role: Role
   isReadOnly: boolean
   isShared: boolean
   isOldVersion: boolean
   currency: Currency
}

export interface BudgetIdResult {
   isFailure: boolean
   isSuccess: boolean
   error: string
   value: string
}

export interface BudgetUserDto {
   userId: string
   userName: string
   userEmail: string
   isCurrentUser: boolean
}

export interface CategoryDto {
   id: string
   categoryGroupId: string
   name: string
   /** @format int32 */
   order: number
   type: CategoryType
   isFund: boolean
   isHidden: boolean
   note: string
}

export interface CategoryGroupDto {
   id: string
   name: string
   budgetId: string
   expenseType: ExpenseType
   type: CategoryType
   /** @format int32 */
   order: number
}

export enum CategoryType {
   Income = 'Income',
   Expense = 'Expense',
   Saving = 'Saving'
}

export interface ChangeBankAccountNameRequest {
   id: string
   name: string
}

export interface ChangeBankAccountOrderRequest {
   /** @format int32 */
   order: number
   bankAccountId: string
}

export interface ChangeCategoryOrderRequest {
   /** @format int32 */
   order: number
   newCategoryGroupId: string
}

export interface CloseBankAccountRequest {
   bankAccountId: string
   targetBankAccountId?: string | null
   /** @format double */
   remainingBalance?: number | null
}

export interface CopyPlansRequest {
   budgetId: string
   sourcePeriodKey: string
   targetPeriodKey: string
}

export interface CreateBankAccountRequest {
   id: string
   budgetId: string
   name: string
   /** @format date */
   date: Date
   /** @format double */
   balance: number
}

export interface CreateBudgetCollaborationRequest {
   budgetId: string
   collaboratorEmail: string
}

export interface CreateBudgetRequest {
   name: string
   currency: Currency
}

export interface CreateCategoryGroupRequest {
   id: string
   budgetId: string
   type: CategoryType
   name: string
}

export interface CreateCategoryRequest {
   id: string
   categoryGroupId: string
   name: string
   isFund: boolean
}

export interface CreateLoanRequest {
   id: string
   budgetId: string
   name: string
   /** @format date */
   targetDate: Date
   paymentType: LoanPaymentType
   /** @format double */
   rate: number
   /** @format double */
   principal: number
   /** @format int32 */
   paymentDay: number
}

export interface CreateOrUpdatePlanRequest {
   categoryId: string
   budgetId: string
   periodKey: string
   /** @format double */
   value: number
   currency: string
}

export interface CreateTransactionTemplateRequest {
   transactionTemplateId: string
   budgetId: string
   bankAccountId?: string | null
   splits?: BaseTransactionCategorySplitDto[] | null
   expensePlans?: BaseTransactionCategorySplitDto[] | null
   /** @format date */
   startDate: Date
   /** @format double */
   value: number
   currency: string
   note: string
   interval: TimeInterval
   type: TransactionType
}

export enum Currency {
   PLN = 'PLN',
   EUR = 'EUR',
   USD = 'USD',
   GBP = 'GBP',
   CAD = 'CAD',
   AUD = 'AUD',
   CZK = 'CZK',
   CHF = 'CHF',
   DKK = 'DKK',
   SEK = 'SEK',
   AED = 'AED',
   AFN = 'AFN',
   ALL = 'ALL',
   AMD = 'AMD',
   ANG = 'ANG',
   AOA = 'AOA',
   ARS = 'ARS',
   AWG = 'AWG',
   AZN = 'AZN',
   BAM = 'BAM',
   BBD = 'BBD',
   BDT = 'BDT',
   BGN = 'BGN',
   BHD = 'BHD',
   BIF = 'BIF',
   BMD = 'BMD',
   BND = 'BND',
   BOB = 'BOB',
   BRL = 'BRL',
   BSD = 'BSD',
   BTN = 'BTN',
   BWP = 'BWP',
   BYN = 'BYN',
   BZD = 'BZD',
   CDF = 'CDF',
   CLF = 'CLF',
   CLP = 'CLP',
   COP = 'COP',
   CRC = 'CRC',
   CUC = 'CUC',
   CUP = 'CUP',
   CVE = 'CVE',
   DJF = 'DJF',
   DOP = 'DOP',
   DZD = 'DZD',
   EGP = 'EGP',
   ERN = 'ERN',
   ETB = 'ETB',
   FJD = 'FJD',
   FKP = 'FKP',
   GEL = 'GEL',
   GHS = 'GHS',
   GIP = 'GIP',
   GMD = 'GMD',
   GNF = 'GNF',
   GTQ = 'GTQ',
   GYD = 'GYD',
   HKD = 'HKD',
   HNL = 'HNL',
   HRK = 'HRK',
   HTG = 'HTG',
   HUF = 'HUF',
   IDR = 'IDR',
   ILS = 'ILS',
   INR = 'INR',
   IQD = 'IQD',
   IRR = 'IRR',
   ISK = 'ISK',
   JMD = 'JMD',
   JOD = 'JOD',
   JPY = 'JPY',
   KES = 'KES',
   KGS = 'KGS',
   KHR = 'KHR',
   KMF = 'KMF',
   KPW = 'KPW',
   KRW = 'KRW',
   KWD = 'KWD',
   KYD = 'KYD',
   KZT = 'KZT',
   LAK = 'LAK',
   LBP = 'LBP',
   LKR = 'LKR',
   LRD = 'LRD',
   LSL = 'LSL',
   LYD = 'LYD',
   MAD = 'MAD',
   MDL = 'MDL',
   MGA = 'MGA',
   MKD = 'MKD',
   MMK = 'MMK',
   MNT = 'MNT',
   MOP = 'MOP',
   MRU = 'MRU',
   MUR = 'MUR',
   MVR = 'MVR',
   MWK = 'MWK',
   MXN = 'MXN',
   MYR = 'MYR',
   MZN = 'MZN',
   NAD = 'NAD',
   NGN = 'NGN',
   NIO = 'NIO',
   NOK = 'NOK',
   NPR = 'NPR',
   NZD = 'NZD',
   OMR = 'OMR',
   PAB = 'PAB',
   PEN = 'PEN',
   PGK = 'PGK',
   PHP = 'PHP',
   PKR = 'PKR',
   PYG = 'PYG',
   QAR = 'QAR',
   RON = 'RON',
   RSD = 'RSD',
   CNY = 'CNY',
   RUB = 'RUB',
   RWF = 'RWF',
   SAR = 'SAR',
   SBD = 'SBD',
   SCR = 'SCR',
   SDG = 'SDG',
   SGD = 'SGD',
   SHP = 'SHP',
   SLL = 'SLL',
   SLE = 'SLE',
   SOS = 'SOS',
   SRD = 'SRD',
   SSP = 'SSP',
   STN = 'STN',
   SVC = 'SVC',
   SYP = 'SYP',
   SZL = 'SZL',
   THB = 'THB',
   TJS = 'TJS',
   TMT = 'TMT',
   TND = 'TND',
   TOP = 'TOP',
   TRY = 'TRY',
   TTD = 'TTD',
   TWD = 'TWD',
   TZS = 'TZS',
   UAH = 'UAH',
   UGX = 'UGX',
   UYU = 'UYU',
   UYW = 'UYW',
   UZS = 'UZS',
   VED = 'VED',
   VES = 'VES',
   VND = 'VND',
   VUV = 'VUV',
   WST = 'WST',
   XCD = 'XCD',
   YER = 'YER',
   ZAR = 'ZAR',
   ZMW = 'ZMW',
   ZWL = 'ZWL'
}

export enum DayOfWeek {
   Sunday = 'Sunday',
   Monday = 'Monday',
   Tuesday = 'Tuesday',
   Wednesday = 'Wednesday',
   Thursday = 'Thursday',
   Friday = 'Friday',
   Saturday = 'Saturday'
}

export interface DeleteCategoryGroupRequest {
   id: string
   targetCategoryId: string
}

export interface DeleteCategoryRequest {
   id: string
   targetCategoryId: string
}

export enum DeviceType {
   Mobile = 'Mobile',
   Desktop = 'Desktop'
}

export enum ExpenseType {
   Housing = 'Housing',
   Food = 'Food',
   Transport = 'Transport',
   Self = 'Self',
   Fun = 'Fun',
   Savings = 'Savings',
   Undefined = 'Undefined'
}

export interface ExportBudgetRequest {
   periodKeyFrom: string
   periodKeyTo: string
}

export interface FilterDto {
   id?: string | null
   name: string
   categories?: string[] | null
   bankAccounts?: string[] | null
}

export interface ImportResultDto {
   /** @format date */
   dateTime: Date
   /** @format double */
   amount: number
   description: string
   receiver: string
   categoryId: string
}

export enum Language {
   PL = 'PL',
   EN = 'EN',
   DE = 'DE'
}

export interface LinkCategoryRequest {
   loanId: string
   categoryId: string
   periodKey: string
}

export interface LoanActivityDto {
   id: string
   loanId: string
   type: LoanActivityType
   value: string
   /** @format date */
   date: Date
   isInitial: boolean
   isGenerated: boolean
}

export enum LoanActivityType {
   Payment = 'Payment',
   InterestPaid = 'InterestPaid',
   InterestRateChange = 'InterestRateChange',
   Reconcile = 'Reconcile',
   TargetDateChange = 'TargetDateChange',
   PaymentTypeChange = 'PaymentTypeChange',
   PrincipalChange = 'PrincipalChange',
   PaymentDayChange = 'PaymentDayChange',
   SkipPayments = 'SkipPayments',
   SkippedPayment = 'SkippedPayment'
}

export interface LoanDto {
   id: string
   budgetId: string
   categoryId?: string | null
   name: string
}

export enum LoanPaymentType {
   Decreasing = 'Decreasing',
   Constant = 'Constant'
}

export interface MovePlanRequest {
   categoryId: string
   targetCategoryId: string
   budgetId: string
   periodKey: string
   /** @format double */
   value: number
}

export interface OnboardingDto {
   firstStepCompleted: boolean
   secondStepCompleted: boolean
   thirdStepCompleted: boolean
   onboardingCompleted: boolean
}

export interface OnboardingScheduledEventDto {
   id: string
   eventName: string
   jsonData: string
}

export enum OnboardingStep {
   FirstStep = 'FirstStep',
   SecondStep = 'SecondStep',
   ThirdStep = 'ThirdStep'
}

export enum OperationType {
   Transaction = 'Transaction',
   Transfer = 'Transfer',
   Reconcile = 'Reconcile'
}

export interface PaymentConfirmation {
   /** @format int32 */
   merchantId: number
   /** @format int32 */
   posId: number
   sessionId: string
   /** @format int32 */
   amount: number
   /** @format int32 */
   originAmount: number
   currency: string
   /** @format int64 */
   orderId: number
   /** @format int32 */
   methodId: number
   statement: string
   sign: string
}

export interface PlanDto {
   categoryId: string
   /** @format double */
   value: number
   periodKey: string
}

export interface PromoCodeDto {
   promoCodeId: string
   name: string
   /** @format double */
   value: number
   isAvailable: boolean
}

export interface ReconcileBankAccountReqeust {
   transactionId: string
   id: string
   /** @format double */
   value: number
   /** @format date */
   date: Date
}

export interface RecordLoanActivityRequest {
   id: string
   loanId: string
   bankAccountId?: string | null
   value?: any
   type: LoanActivityType
   /** @format date */
   date: Date
}

export interface RecordUserVisitRequest {
   clientId: string
   sessionId: string
   sessionNumber: string
}

export interface ReferralCodeDto {
   code: string
   /** @format int32 */
   paidUsageCount: number
   /** @format int32 */
   usageCount: number
}

export interface RejectTransactionTemplateRequest {
   transactionTemplateId: string
   /** @format date */
   templateTransactionDate: Date
}

export enum RepeatPeriod {
   Year = 'Year',
   Month = 'Month'
}

export enum Role {
   Owner = 'Owner',
   Guest = 'Guest'
}

export interface SaveAnalyticsDataRequest {
   acquisitionData: string
   device: DeviceType
}

export interface SaveCategoryNoteRequest {
   id: string
   note: string
}

export interface SaveNPSSurveyResponseRequest {
   data: string
   /** @format int32 */
   score: number
   isMobile: boolean
   surveyAction: SurveyAction
}

export interface ScheduleOnboardingEventRequest {
   eventName: string
   jsonData: string
}

export interface ScheduledLoanPaymentDto {
   loanId: string
   /** @format date */
   dateTime: Date
   /** @format double */
   principal: number
   /** @format double */
   interest: number
}

export interface SetTargetRequest {
   categoryId: string
   startPeriodKey: string
   endPeriodKey: string
   /** @format double */
   value?: number | null
   /** @format int32 */
   dueDay?: number | null
   /** @format date */
   dueDate?: Date
   repeat: boolean
   repeatPeriod?: RepeatPeriod | null
   /** @format int32 */
   repeatFrequency?: number | null
   type: TargetType
}

export interface SpecialOfferDto {
   id: string
   /** @format date */
   endDate: Date
   isOfferActive: boolean
   seen: boolean
   /** @format double */
   value: number
}

export interface StartPaymentRequest {
   /** @format uuid */
   specialOfferId: string
   /** @format uuid */
   promoCodeId: string
   subscriptionPlanType: SubscriptionPlanType
   /** @format int32 */
   quantity: number
}

export interface SubscriptionDto {
   /** @format date */
   expiresAt: Date
   type: SubscriptionType
   isExpired: boolean
}

export enum SubscriptionPlanType {
   Monthly = 'Monthly',
   Annual = 'Annual'
}

export enum SubscriptionType {
   Trial = 'Trial',
   Premium = 'Premium'
}

export enum SurveyAction {
   Completed = 'Completed',
   Skipped = 'Skipped'
}

export interface SurveyDto {
   /** @format date */
   dateTime?: Date
   surveyAction?: SurveyAction | null
}

export interface TargetDto {
   id: string
   categoryId: string
   /** @format double */
   value?: number | null
   startPeriodKey: string
   endPeriodKey: string
   /** @format int32 */
   dueDay?: number | null
   /** @format date */
   dueDate?: Date
   repeat: boolean
   repeatPeriod?: RepeatPeriod | null
   /** @format int32 */
   repeatFrequency?: number | null
   type: TargetType
}

export enum TargetType {
   NeededForSpendingMonthly = 'NeededForSpendingMonthly',
   NeededForSpendingByDate = 'NeededForSpendingByDate',
   SavingsBuilder = 'SavingsBuilder',
   SavingsBuilderMonthly = 'SavingsBuilderMonthly',
   DebtPayment = 'DebtPayment'
}

export enum TimeInterval {
   Once = 'Once',
   Daily = 'Daily',
   Weekly = 'Weekly',
   Monthly = 'Monthly',
   Quarterly = 'Quarterly',
   Yearly = 'Yearly'
}

export interface TransactionDraftDto {
   id: string
   /** @format double */
   value: number
   currency: string
   description: string
   bankAccountId?: string | null
   categoryId?: string | null
   budgetId: string
   /** @format date */
   date: Date
   paidBy: string
   paidById: string
   transactionType: TransactionType
}

export interface TransactionDto {
   id: string
   /** @format double */
   value: number
   currency: string
   bankAccountId?: string | null
   targetBankAccountId?: string | null
   budgetId: string
   note: string
   periodKey: string
   /** @format date */
   date: Date
   paidBy: string
   paidById: string
   status?: TransactionStatus | null
   operationType: OperationType
   transactionType: TransactionType
   isLinkedToLoanActivity: boolean
}

export interface TransactionSplitDto {
   id: string
   categoryId: string
   categoryName: string
   categoryType: string
   /** @format double */
   value: number
   currency: string
   transactionId: string
   bankAccountId?: string | null
   budgetId: string
   note: string
   paidBy: string
   paidById: string
   periodKey: string
   /** @format date */
   date: Date
   type: TransactionType
   transactionTemplateId: string
   status?: TransactionStatus | null
}

export enum TransactionStatus {
   Draft = 'Draft',
   Regular = 'Regular'
}

export interface TransactionTemplateDto {
   id: string
   /** @format double */
   value: number
   currency: string
   budgetId: string
   bankAccountId?: string | null
   note: string
   paidBy: string
   paidById: string
   type: TransactionType
   interval: TimeInterval
   /** @format date */
   startDate: Date
   usages?: TransactionTemplateUsageDto[] | null
}

export interface TransactionTemplateSplitDto {
   id: string
   categoryId: string
   categoryName: string
   categoryType: string
   /** @format double */
   value: number
   currency: string
   transactionTemplateId: string
}

export interface TransactionTemplateUsageDto {
   id: string
   transactionTemplateId: string
   /** @format date */
   templateTransactionDate: Date
   status: TransactionTemplateUsageStatus
}

export enum TransactionTemplateUsageStatus {
   Accepted = 'Accepted',
   Rejected = 'Rejected'
}

export enum TransactionType {
   Inflow = 'Inflow',
   Outflow = 'Outflow',
   Internal = 'Internal'
}

export interface UpdateBudgetRequest {
   id: string
   currency: Currency
   name: string
}

export interface UpdateCategoryGroupRequest {
   id: string
   name: string
   /** @format int32 */
   order: number
}

export interface UpdateCategoryRequest {
   id: string
   name: string
}

export interface UpdateLoanActivityRequest {
   loanId: string
   id: string
   value?: any
   /** @format date */
   date: Date
   bankAccountId?: string | null
}

export interface UpdateLoanRequest {
   loanId: string
   name: string
}

export interface UpdateProfileRequest {
   /** @format uuid */
   userId: string
   name: string
   secret: string
}

export interface UpdateReconcileBankAccountReqeust {
   transactionId: string
   bankAccountId: string
   /** @format double */
   value: number
   /** @format date */
   date: Date
}

export interface UpdateReminderSettingsRequest {
   reminderDay?: DayOfWeek | null
   /** @format int32 */
   reminderHour?: number | null
   /** @format int32 */
   reminderMinute?: number | null
   isBudgetingReminderActive: boolean
}

export interface UpdateTransactionRequest {
   transactionId: string
   bankAccountId: string
   splits?: BaseTransactionCategorySplitDto[] | null
   budgetId: string
   /** @format date */
   date: Date
   /** @format double */
   value: number
   currency: string
   note: string
   type: TransactionType
}

export interface UpdateTransactionTemplateRequest {
   transactionTemplateId: string
   bankAccountId?: string | null
   splits?: BaseTransactionCategorySplitDto[] | null
   expensePlans?: BaseTransactionCategorySplitDto[] | null
   /** @format date */
   startDate: Date
   /** @format double */
   value: number
   currency: string
   note: string
   interval: TimeInterval
   type: TransactionType
}

export interface UpdateTransferRequest {
   transactionId: string
   budgetId: string
   /** @format date */
   date: Date
   /** @format double */
   value: number
   currency: string
   note: string
}

export interface UpsertFilterRequest {
   id: string
   budgetId: string
   name: string
   categories?: string[] | null
   bankAccounts?: string[] | null
}

export interface UseTransactionTemplateRequest {
   transactionId: string
   transactionTemplateId: string
   bankAccountId: string
   /** @format date */
   date: Date
   /** @format date */
   templateTransactionDate: Date
   /** @format double */
   value: number
   currency: string
   note: string
   splits?: BaseTransactionCategorySplitDto[] | null
   expensePlans?: BaseTransactionCategorySplitDto[] | null
   type: TransactionType
}

export interface UserDto {
   id: string
   email: string
   name: string
   /** @format date */
   createdAt?: Date
   finishedOnboarding: boolean
   isEmailConfirmed: boolean
   /** @format int32 */
   seenCount: number
   isDisabled: boolean
   referral: string
}

export interface UserSettingsDto {
   isBudgetingReminderActive: boolean
   defaultBudgetId: string
   defaultBankAccountId: string
   reminderDay?: DayOfWeek | null
   /** @format int32 */
   reminderHour: number
   /** @format int32 */
   reminderMinute: number
   lastSeenPeriodKey?: string | null
   language: Language
}
