import { useEventBus } from './useEventBus'

export function useFreshDesk() {
   const freshDesk = (window as any).FreshworksWidget
   const hideLauncher = () => {
      freshDesk('hide', 'launcher')
   }

   const openWidget = (articleId?: string) => {
      if (articleId) {
         freshDesk('open', 'article', { id: articleId })
      } else {
         freshDesk('open')
      }

      useEventBus().emit('helpOpened')
   }

   const openContactForm = () => {
      freshDesk('open', 'ticketForm')
   }

   return {
      hideLauncher,
      openWidget,
      openContactForm
   }
}
